import React, { useEffect, useState } from 'react';
import './app.css';

function App() {
  const [users, setUsers] = useState([]);
  const [connected, setConnected] = useState(false);
  const [prompt, setPrompt] = useState('');  // Ollama에 보낼 프롬프트
  const [ollamaResponse, setOllamaResponse] = useState('');  // Ollama의 응답

  // API 호출하여 사용자 목록 가져오기
  useEffect(() => {
    fetch('/api/users')
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => setUsers(data))
      .catch((error) => console.error('Error fetching users:', error));

    // WebSocket URL 설정 - 현재 도메인에 맞춰 설정
    const socketUrl = `wss://${window.location.hostname}/ws`;

    const connectWebSocket = () => {
      const socket = new WebSocket(socketUrl);

      socket.onopen = () => {
        console.log('WebSocket connection established');
        setConnected(true);
      };

      socket.onmessage = (event) => {
        console.log('Message from server:', event.data);
      };

      socket.onclose = () => {
        console.log('WebSocket connection closed. Attempting to reconnect...');
        setConnected(false);
        setTimeout(connectWebSocket, 5000); // 5초 후 재시도
      };

      socket.onerror = (error) => {
        console.error('WebSocket Error:', error);
      };

      // 컴포넌트가 언마운트될 때 WebSocket 연결 종료
      return () => {
        socket.close();
      };
    };

    connectWebSocket();
  }, []);

  // Ollama API 호출
  const handleOllamaQuery = () => {
    fetch('/api/generate', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ prompt }),  // 사용자가 입력한 프롬프트 전송
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Ollama query failed');
        }
        return response.json();
      })
      .then((data) => setOllamaResponse(data.response))  // Ollama의 응답 저장
      .catch((error) => console.error('Error querying Ollama:', error));
  };

  return (
    <div className="App">
      <header className="App-header">
        <nav className="navbar">
          <div className="logo">KuroBean's Website</div>
        </nav>
      </header>
      <main className="main-content">
        <section id="home" className="section">
          <h1>Welcome to My Website!</h1>
          <p>This is a KuroBean's Private Website.</p>
          <p>Using React / Express / MySQL / Ollama LLM</p>
        </section>

        <section id="users" className="section">
          <h2>MySQL 데이터베이스 연동 확인</h2>
          <ul>
            {users.map(user => (
              <li key={user.id}>{user.username} - {user.password}</li>
            ))}
          </ul>
        </section>

        {/* Ollama gemma2 모델과의 상호작용 섹션 추가 */}
        <section id="ollama" className="section">
          <h2>LLM 연동 확인(Ollama)</h2>
          <textarea
            className="textarea"  // 여기에 클래스를 추가
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
            placeholder="Ask something to Ollama LLM model..."
          />
          <button onClick={handleOllamaQuery}>Submit</button>
          {ollamaResponse && (
            <div>
              <h2>Response:</h2>
              <p>{ollamaResponse}</p>
            </div>
          )}
        </section>
      </main>

      <footer className="footer">
        <p>&copy; {new Date().getFullYear()} KuroBean's Website. All rights reserved.</p>
        {connected ? <p>Connected to WebSocket!</p> : <p>Connecting...</p>}
      </footer>
    </div>
  );
}

export default App;
