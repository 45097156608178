// index.js 파일을 수정하세요
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './app'; // 대문자 A에서 소문자 a로 변경

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
